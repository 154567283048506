<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <analises-setorizadas-navigation-tabs
          :selectedTab.sync="selectedTabNavegationTabs"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <analises-setorizadas-data-table
          ref="analisesSetorizadasDataTable"
          :titulo="tituloTabela"
          :items="items"
          :loading="loading"
          @rechargeTable="chargeTableDecision(tipoListaAnaliseSetorizada)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AnalisesSetorizadasService from '@/services/AnalisesSetorizadasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    AnalisesSetorizadasNavigationTabs: () =>
      import(
        '@/components/analises-setorizadas/AnalisesSetorizadasNavigationTabs'
      ),
    AnalisesSetorizadasDataTable: () =>
      import('@/components/analises-setorizadas/AnalisesSetorizadasDataTable')
  },

  data: () => ({
    items: [],
    tituloTabela: 'Análises Setorizadas',
    selectedTabNavegationTabs: 0,
    arrTour: [
      {
        element: '#tab-historico',
        intro:
          'Selecionando esta aba você poderá acessar todas as análises cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-favoritos',
        intro:
          'Selecionando esta opção você poderá acessar as análises que você definir como suas favoritas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#btn-nova-analise',
        intro:
          'Clicando sobre este botão você poderá criar novas análises setorizadas.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#analises-historico-search',
        intro: 'Neste campo você poderá pesquisar por análises já cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#setorizacao-nome',
        intro: 'Aqui você tem acesso ao tipo de setorização selecionada.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#numero-setores',
        intro:
          'Este é o número de setores gerados após o processamento da análise setorizada.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-visualizar',
        intro:
          'Nesta opção você poderá visualizar o mapa coroplético resultado da análise.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-favoritar-rel',
        intro:
          'Clicando sobre este botão você poderá marcar esta análise como favorita, podendo acessá-la mais rapidamente através do menu superior "FAVORITAS".',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel',
        intro:
          'Aqui você acessa outras opções para sua análise setorizada, como executá-la novamente, deletá-la e é possivel visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.chargeTableDecision(this.tipoListaAnaliseSetorizada);
  },

  computed: {
    tipoListaAnaliseSetorizada() {
      return this.$store.getters.getTipoListaAnaliseSetorizada;
    }
  },

  methods: {
    getAnalisesSetorizadas() {
      this.items = [];
      this.loading = true;
      AnalisesSetorizadasService.index()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    getAnalisesSetorizadasFavoritadas() {
      this.items = [];
      this.loading = true;
      AnalisesSetorizadasService.getAnalisesSetorizadasFavoritadas()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    chargeTableDecision(newDecisionInfo) {
      if (this.$refs.analisesSetorizadasDataTable) {
        this.$refs.analisesSetorizadasDataTable.page = 1;
      }
      this.tituloTabela = newDecisionInfo.titulo;

      switch (newDecisionInfo.tipo) {
        case 'favoritos':
          this.selectedTabNavegationTabs = 1;
          this.cancelRefreshData();
          this.refreshData(this.getAnalisesSetorizadasFavoritadas);
          break;
        case 'historico':
        default:
          this.selectedTabNavegationTabs = 0;
          this.cancelRefreshData();
          this.refreshData(this.getAnalisesSetorizadas);
          break;
      }
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 4);
      this.iniciarTour(arrTourFormatted);
    }
  },

  watch: {
    tipoListaAnaliseSetorizada(newValue) {
      this.chargeTableDecision(newValue);
    }
  }
};
</script>
